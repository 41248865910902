import LogsService from '@/service/LogsService'

export default {
  namespaced: true,
  state: {
    logs: [],
    FtpLogs: [],
  },
  getters: {
    logs(state) {
      return state.logs
    },
    getFtpLogs(state) {
      return state.FtpLogs
    },
  },
  mutations: {
    UPDATE_LOGS(state, logs) {
      state.logs = logs
    },
    UPDATE_FTP_LOGS(state, logs) {
      state.FtpLogs = logs
    },
  },
  actions: {
    async findAllByLocal({ commit }, local) {
      const logs = await LogsService.findAllByLocal(local)
      commit('UPDATE_LOGS', logs)
    },
    resetLogs({ commit }) {
      commit('UPDATE_LOGS', [])
    },
    async saveLogLoginCliente(_, saveLogLogin) {
      await LogsService.saveLogLoginCliente(saveLogLogin)
    },
    async saveLogLoinUnidade(_, saveLogLogin) {
      await LogsService.saveLogLoinUnidade(saveLogLogin)
    },
    async getFtpLogs({ commit }, filtroBuscaLogFtp) {
      const ftpLogs = await LogsService.getFtpLogs(filtroBuscaLogFtp)
      commit('UPDATE_FTP_LOGS', ftpLogs)
    },
  },
}