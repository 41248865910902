import HttpClient from '@/service/http-clients/portaljad'

const baseUrl = 'logs'

export default class LogsService {
  static async findAllByLocal({ logLocal, idCliente }) {
    try {
      const { data } = await HttpClient.post([baseUrl, logLocal].join('/'), idCliente)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async saveLogLoginCliente(saveLogLogin) {
    try {
      await HttpClient.post([baseUrl, 'login', 'cliente'].join('/'), saveLogLogin)
    } catch (error) {
      throw error.response.data
    }
  }

  static async saveLogLoinUnidade(saveLogLogin) {
    try {
      await HttpClient.post([baseUrl, 'login', 'unidade'].join('/'), saveLogLogin) 
    } catch (error) {
      throw error.response.data
    }
  }

  static async getFtpLogs(filtroBuscaLogFtp) {
    try {
      const { data } = await HttpClient.post([baseUrl, 'log-ftp'].join('/'), filtroBuscaLogFtp) 
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}