<template>
  <div>
    <add-usuario-prime-sidebar
      :active.sync="addUsuarioPrimeSidebarActive"
      @aoSalvar="atualizaLista"
    />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="500"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>usuários por página</label>
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox
              v-model="mostrarInativos"
            >
              <label>Mostrar Usuários Inativos</label>
            </b-form-checkbox>
          </b-col>
          <b-col 
            cols="12"
            md="3"
            class="d-flex justify-content-end"
          >
            <b-button
              variant="primary"
              @click="addUsuarioPrimeSidebarActive = true"
            >
              <span>Adicionar Usuário Prime</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="tableRef"
        responsive
        class="position-relative pb-3"
        :items="usuarios"
        :fields="tableColumns"
        :filter="search"
        :per-page="perPage"
        :current-page="currentPage"
        show-empty
        stacked="md"
        empty-text="Não há usuários prime cadastrados."
      >
        <template #cell(ativo)="data">
          <b-badge
            v-if="data.item.ativo"
            variant="success"
          >
            SIM
          </b-badge>
          <b-badge
            v-else
            variant="light-primary"
          >
            NÃO
          </b-badge>
        </template>
        <template 
          #cell(ações)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item 
              :to="{ name: 'usuarios-prime-visualizar', params: { usuarioId: data.item.usuarioId} }"
            >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">Detalhes</span>
            </b-dropdown-item>
            <b-dropdown-item
              :to="{ name: 'usuarios-prime-editar', params: {usuarioId: data.item.usuarioId}}"
            >
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
            <b-dropdown-item 
              @click="alterarStatusUser(data.item)"
            >
              <feather-icon
                :icon="data.item.ativo ? 'PauseCircleIcon' : 'PlayCircleIcon' "
              />
              <span class="align-middle ml-50">{{ data.item.ativo ? 'Inativar' : 'Ativar' }}
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </div> 
</template>
<script>

// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
import { reactive, toRefs, onBeforeMount, watch } from '@vue/composition-api'
import store from '@/store'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { $themeConfig } from '@themeConfig'
import Utils from '@/helpers/utils'
import AddUsuarioPrimeSidebar from './components/add-usuario-prime-sidebar.vue'

export default {
  name: 'UsuarioPrime',
  components: { AddUsuarioPrimeSidebar },
  setup() {
    const { appCarregando } = $themeConfig.app

    const usuariosStore = createNamespacedHelpers('usuarios')
    const { mostarInativosPrime } = usuariosStore.useActions(['mostarInativosPrime'])

    const state = reactive({
      usuarios: usuariosStore.useGetters(['usuariosPrime']).usuariosPrime,
      mostrarInativos: false,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      search: '',
      perPageOptions: [10, 25, 50, 100],
      addUsuarioPrimeSidebarActive: false,
    })

    const refresh = () => {
      const nivel = 'PRIME'
      appCarregando.show()
      store.dispatch('usuarios/findAllByNivel', nivel).then(() => {
        if (!state.mostrarInativos) {
          mostarInativosPrime(state.mostrarInativos)
        }
        appCarregando.hide()
      })
    }

    onBeforeMount(() => {
      refresh()
    })

    watch(() => state.mostrarInativos, () => {
      refresh()
    })

    const tableColumns = [
      { key: 'nome', sortable: true },
      { key: 'email', sortable: true },
      { key: 'ativo', sortable: true },
      { key: 'ações', sortable: false },
    ]

    const atualizaLista = () => {
      refresh()
    }

    const alterarStatusUser = usuario => {
      const msg = usuario.ativo ? 'INATIVAR' : 'ATIVAR'
      swal.fire({
        title: 'Atenção!',
        icon: 'warning',
        html: `Você tem certeza que quer ${msg} esse usuário?`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim',
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          appCarregando.show()
          const { usuarioId, ativo } = usuario
          // eslint-disable-next-line no-param-reassign
          usuario.ativo = Number(!ativo)
          store.dispatch('usuarios/edit', { usuarioId, usuario }).then(() => {
            appCarregando.hide()
            swal.fire({
              icon: 'success',
              title: 'Inativado com sucesso!',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            }).then(() => refresh())
          }).catch(e => {
            swal.fire({
              icon: 'error',
              title: 'Erro!',
              html: `Erro; ${e.message}`,
              showConfirmButton: true,
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    return {
      ...toRefs(state),
      ...Utils,
      tableColumns,
      atualizaLista,
      alterarStatusUser,
    }
  },
  
}
</script>
<style lang="">
  
</style>