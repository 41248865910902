<template>
  <component :is="usuario ? 'b-card' : 'div'">
    <b-card v-if="usuario">
      <b-media class="mb-2">
        <template #aside>
          <b-avatar
            ref="previewEl"
            :text="avatarText(usuario.nome)"
            variant="light-success"
            size="90px"
            rounded
          />
        </template>
        <h4 class="mb-1">
          {{ usuario.nome }}
        </h4>
        <h5 class="mb-1">
          {{ usuario.login }}
        </h5>
      </b-media>
      <validation-observer
        #default="{handleSubmit}"
        ref="refFormObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                #default="validationContext"
                name="Nome"
                :rules="{ required: true }"
              >
                <b-form-group
                  label="Nome"
                  label-for="nome"
                >
                  <b-form-input
                    id="nome"
                    v-model="usuario.nome"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="João da Silva"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                #default="validationContext"
                name="Email"
              >
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="usuario.email"
                    disabled
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="usuario@dominio.com"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              sm="12"
            >
              <validation-provider
                #default="validationContext"
                name="Clientes"
                rules="required"
              >
                <b-form-group
                  label="Clientes"
                  label-for="clientesPrime"
                >
                  <v-select
                    id="clientesPrime"
                    v-model="usuarioPrime.clienteId"
                    multiple
                    :options="clientesOptions"
                    :reduce="val=>val.value"
                    :close-on-select="false"
                  />
                  <span class="undermessage-error">
                    {{ validationContext.errors[0] }}
                  </span>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <b-button
        variant="primary"
        class="mb-1 mt-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="salvarUsuario()"
      >
        Salvar Mudanças
        <feather-icon
          icon="SaveIcon"
          class="ml-1"
        />
      </b-button>
      <b-button
        variant="outline-secondary"
        class="mr-sm-1 mr-0 mb-1 mt-1 mb-sm-0"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="$router.go(-1)"
      >
        Cancelar Alterações
        <feather-icon
          icon="RefreshCwIcon"
          class="ml-1"
        />
      </b-button>
      <b-button
        variant="outline-secondary"
        class="mt-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="deleteUser(usuario)"
      >
        Deletar
        <feather-icon
          icon="TrashIcon"
          class="ml-1"
        />
      </b-button>
    </b-card>
  </component>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
import { avatarText } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import { reactive, toRefs } from '@vue/composition-api'
import cleaveOptions from '@/libs/cleave/options'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { $themeConfig } from '@themeConfig'
import store from '@/store'

const usuariosStore = createNamespacedHelpers('usuarios')
const clienteStores = createNamespacedHelpers('clientes')

const appLoading = $themeConfig.app.appLoadingImage

export default {
  name: 'UsuarioPrimeEditar',
  beforeMount() {
    this.$store.dispatch('usuarios/findById', this.$route.params.usuarioId)
    this.$store.dispatch('clientes/findAll').then(() => {
      this.$store.dispatch('clientes/setOptions')
      this.$store.dispatch('clientes/findByUsuariosPrimeId', this.$route.params.usuarioId)
    })
  },
  setup() {
    const { empresaId } = JSON.parse(localStorage.getItem('userName'))
    const { findById } = usuariosStore.useActions(['findById'])

    const state = reactive({
      usuario: usuariosStore.useGetters(['selected']).selected,
      clientesOptions: clienteStores.useGetters(['clientesOptions']).clientesOptions,
      usuarioPrime: clienteStores.useGetters(['clientesPrime']).clientesPrime,
    })

    const resetUsuario = () => {
      findById(state.usuario.usuarioId)
    }

    const deleteUser = user => {
      const { usuarioId } = user
      swal.fire({
        title: 'Atenção!',
        icon: 'warning',
        html: 'Você tem certeza que quer deletar esse usuário?',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim',
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          appLoading.show()
          store.dispatch('usuarios/delete', usuarioId).then(() => {
            appLoading.hide()
            swal.fire({
              icon: 'success',
              html:
              '<h1>Deletado com sucesso!</h1>',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          }).then(() => {
            router.push('/cadastro/usuario-prime')
          })
        }
      })
    }

    const salvarUsuario = () => {
      swal.fire({
        icon: 'warning',
        title: 'Atenção!',
        text: 'Deseja salvar as alterações?',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const { usuario } = state
          const listPrime = []
          state.usuarioPrime.clienteId.forEach(c => {
            listPrime.push({
              clienteId: c,
              empresaId,
            })
          })
          const user = {
            ...usuario,
            usuarioPrime: listPrime,
          }
          store.dispatch('usuarios/edit', {
            usuarioId: usuario.usuarioId,
            usuario: user,
          }).then(() => {
            swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Você alterou os dados do usuário!',
              showConfirmButton: false,
              timer: 2000,
            })
          }).then(() => {
            router.push('/cadastro/usuario-prime')
          })
        }
      })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(resetUsuario)
    return {
      // REFS
      ...toRefs(state),
      // FORM FUNCS
      refFormObserver,
      getValidationState,
      // MISC
      avatarText,
      cleaveOptions,
      deleteUser,
      salvarUsuario,
    }
  },
}
</script>

<style scoped>

</style>