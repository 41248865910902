<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="active"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    no-header
    right
    @hidden="resetFormRegiao"
    @change="(val) => $emit('update:active', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Importar Regiões
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <!-- CAMPOS -->
      <validation-observer
        #default="{handleSubmit}"
        ref="refFormObserver"
      >
        <b-form
          ref="formRegiao"
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="Nome"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Nome"
              label-for="nome"
            >
              <b-form-input
                id="nome"
                v-model="regiaoImportacao.nome"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="CIDATEN2 - 00002022"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <FormDatepicker
            id="dataVigencia"
            ref="refdate"
            v-model="regiaoImportacao.dataVigencia"
            label="Data de Vigência"
            :get-validation-state="getValidationState"
            :required="true"
            @update="(novaData) => regiaoImportacao.dataVigencia = novaData"
          />
          <b-form-group
            label="Selecione o Cliente"
            label-for="cliente"
          >
            <v-select
              id="cliente"
              v-model="regiaoImportacao.clienteId"
              nome="cliente"
              :reduce="val=>val.value"
              :clearable="false"
              :options="clientesOptions"
            />
          </b-form-group>
          <validation-provider
            #default="validationContext"
            name="pesoMinimo"
          >
            <b-form-group
              label="Peso negociado a partir de:"
              label-for="pesoMinimo"
            >
              <b-form-input
                id="pesoMinimo"
                v-model="regiaoImportacao.pesoMinimo"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Padrão sem valor"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="arquivoImportacao"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Arquivo de Importacao"
              label-for="arquivoImportacao"
            >
              <b-form-file
                v-model="arquivoImportacao"
                placeholder="Escolha a planilha"
                drop-placeholder="Solte a planilha aqui"
                accept=".csv, .xls, .xlsx"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <a
                href="https://portaljad.s3.sa-east-1.amazonaws.com/arquivo-regiao/852197000/ModeloCIDATENJadlog.xlsx"
                target="_blank"
                class="link-primary"
              >
                Layout modelo a ser seguido
              </a>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Adicionar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
import formValidation from '@core/comp-functions/forms/form-validation'
import lodash from 'lodash'
import { reactive, toRefs, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import FormDatepicker from '@/components/form/FormDatepicker.vue'
import { $themeConfig } from '@themeConfig'

export default {
  name: 'IndexVue',
  components: { FormDatepicker },
  emits: ['aoSalvar'],
  props: { active: { required: true, type: Boolean } },
  setup(_, { emit }) {
    const blankRegiaoImportacao = {
      nome: null,
      dataVigencia: null,
      nomeArquivoImportado: null,
      clienteId: null,
      pesoMinimo: null,
      urlArquivoImportado: null,
      ativo: true,
    }

    const regioesStore = createNamespacedHelpers('regioes')
    const clientesStore = createNamespacedHelpers('clientes')

    const { save } = regioesStore.useActions(['save'])

    const state = reactive({
      clientes: clientesStore.useGetters(['clientes']).clientes,
      clientesOptions: [{ value: null, label: 'Padrão' }],
      regiaoImportacao: lodash.cloneDeep(blankRegiaoImportacao),
      arquivoImportacao: null,
    })

    watch(() => state.clientes, value => {
      value.forEach(elemento => {
        state.clientesOptions.push({ label: elemento.nomeFantasia,
          value: elemento.clienteId })
      })
    })

    const resetRegiao = () => {
      state.regiaoImportacao = lodash.cloneDeep(blankRegiaoImportacao)
      state.arquivoImportacao = null
    }

    const appLoading = $themeConfig.app.appLoadingImage

    const { getValidationState, resetForm, refFormObserver } = formValidation(resetRegiao)

    const createMultipart = () => {
      const multipart = new FormData()
      multipart.append('file', state.arquivoImportacao)
      multipart.append('regiaoImportacao', JSON.stringify(state.regiaoImportacao))
      return multipart
    }

    const onSubmit = () => {
      appLoading.show()
      const multipart = createMultipart()
      save(multipart).then(response => {
        appLoading.hide()
        emit('update:active', false)
        emit('aoSalvar')
        if (!response.success) {
          swal.fire({
            icon: 'info',
            html: `<h1>${response.body}</h1>`,
            width: 800,
            showConfirmButton: true,
          })
        }
      })
    }

    return {
      ...toRefs(state),
      // FORM FUNCS
      getValidationState,
      resetForm,
      refFormObserver,
      // MISC
      onSubmit,
      resetRegiao,
    }
  },
  methods: {
    resetFormRegiao() {
      this.$refs.formRegiao.reset()
      this.$refs.refdate.clear()
    },
  },
}
</script>

<style scoped>

</style>