<template>
  <div>
    <cte-tracking-sidebar
      :active.sync="showCteTrackingSidebar"
      @aoFechar="limpaCte"
    />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <validation-observer #default="{handleSubmit}">
          <b-form @submit.prevent="handleSubmit(onSubmit('NOVA_PESQUISA'))">
            <b-row>
              <b-col
                md="3"
              >
                <validation-provider
                  #default="validationContext"
                  v-model="dateRange"
                  name="date-range"
                >
                  <date-range :date-range.sync="dateRange"/>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="3"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <b-form-group
                  label="Tipo de Envio"
                  label-for="tipo"
                >
                  <v-select
                    id="tipo"
                    v-model="filtros.reversa"
                    :options="reversaOptions"
                    :reduce="val=>val.value"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <b-form-group
                  label="Cliente"
                  label-for="cliente"
                >
                  <v-select
                    id="cliente"
                    v-model="filtros.clienteId"
                    :options="selectClientes"
                    :reduce="val=>val.value"
                    :clearable="true"
                    placeholder="Todos"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <b-form-group 
                  class="m1"
                  label="Nota Fiscal"
                  label-for="numeroNotaFiscal"
                >
                  <b-form-input
                    id="numeroNotaFiscal"
                    v-model="filtros.numeroNotaFiscal"
                    placeholder="Nota Fiscal"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <b-input-group class="m1">
                  <b-form-input
                    v-model="filtros.shippmentId"
                    placeholder="Shippment Id"
                  />
                  <b-input-group-append>
                    <b-input-group-text>
                      <feather-icon
                        icon="MapPinIcon"
                        size="15"
                      />
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col
                md="3"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <b-input-group class="m1">
                  <b-form-input
                    v-model="filtros.cte"
                    placeholder="CTE"
                  />
                  <b-input-group-append>
                    <b-input-group-text>
                      <feather-icon
                        icon="UserIcon"
                        size="15"
                      />
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col
                md="3"
                horizontal="md"
                class="d-flex justify-content-between align-items-center flex-wrap"
              >
                <b-button
                  variant="primary"
                  class="button-group flex-fill"
                  type="submit"
                >
                  <span class="text-nowrap">Pesquisar</span>
                </b-button>
              </b-col>
              <b-col
                md="3"
                horizontal="md"
                class="d-flex justify-content-between align-items-center flex-wrap"
              >
                <b-button
                  variant="primary"
                  class="px-1 my-1 button-group flex-fill"
                  @click="gerarExcel"
                >
                  <span class="text-nowrap">Exportar Excel</span>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
                class="mt-1"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="search"
                    class="d-inline-block"
                    placeholder="Pesquisar..."
                    debounce="1000"
                  />
                </div>
              </b-col>
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-end mt-1 mb-1 mb-md-0"
              >
                <label>Mostrando</label>
                <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                  @input="changePerPage"
                />
                <label>envios por página</label>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
      <b-table
        id="my-table"
        ref="tableRef"
        responsive
        class="position-relative"
        :items="dados.lista"
        :fields="tableColumns"
        primary-key="envioId"
        show-empty
        stacked="md"
        :empty-text="carregando ? 'Carregando...' : 'Nenhum envio cadastrado'"
        :no-local-sorting="true"
        @sort-changed="sortingChanged"
      >
        <template #head(select)="{}">
          <div class="d-flex justify-content-center">
            <b-form-checkbox
              v-model="allSelected"
              @change="selectAllHandler"
            />
          </div>
        </template>
        <template #cell(select)="data">
          <div class="d-flex justify-content-center">
            <b-checkbox 
              v-model="itemSelecionado(data.item.movimentacaoId).selecionado"
            />
          </div>
        </template>
        <template #cell(shippmentId)="data">
          <a
            :href="abirTrack(data.item.shippmentId)" 
            target="_blank"
            rel="noopener noreferrer"
            class="hyper-link"
          >
            {{ data.item.shippmentId }}
          </a>
        </template>
        <template #cell(cte)="data">
          <div
            class="hyper-link"
            @click="abrirCteTracking(data.item.cte)"
          >
            {{ data.item.cte }}
          </div>
        </template>
        <template #cell(dataEnvio)="data">
          <div>
            {{ data.item.dataEnvio ? maskDate(data.item.dataEnvio) : '' }}
          </div>
        </template>
        <template #cell(numeroDocumento)="data">
          <div>
            {{ data.item.numeroDocumento ? maskCnpj(data.item.numeroDocumento) : '' }}
          </div>
        </template>
        <template #cell(clienteNome)="data">
          <div>
            {{ data.item.preCadastro ? data.item.clienteNome + ' - Pré Cadastro' : data.item.clienteNome }}
          </div>
        </template>
        <template #cell(nomeServico)="data">
          <div>
            {{ data.item.nomeServico ? serviceDictionary(data.item.nomeServico) : '' }}
          </div>
        </template>
        <template #cell(cepDestinatario)="data">
          <div>
            {{ data.item.cepDestinatario ? maskCep(`${data.item.cepDestinatario}`) : '' }}
          </div>
        </template>
        <template #cell(valorParaSeguro)="data">
          <div>
            {{ data.item.valorParaSeguro ? maskCurrency(Number(data.item.valorParaSeguro)) : '' }}
          </div>
        </template>
        <template #cell(valorTotalTaxado)="data">
          <div>
            {{ data.item.valorTotalTaxado ? maskCurrency(Number(data.item.valorTotalTaxado)) : '' }}
          </div>
        </template>
        <template #cell(dataUltimoEvento)="data">
          <div>
            {{ data.item.dataUltimoEvento ? maskDateHour(data.item.dataUltimoEvento) : '' }}
          </div>
        </template>
        <template #cell(pesoTotalTaxado)="data">
          <div>
            {{ data.item.pesoTotalTaxado ? maskCurrency(Number(Number(data.item.pesoTotalTaxado)/1000).toFixed(2)) : '' }}
          </div>
        </template>
        <template #cell(reversa)="data">
          <div>
            {{ data.item.reversa ? "Reversa" : "Envio" }}
          </div>
        </template>
        <template #cell(acoes)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="openModal(data)"
            >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">Detalhes</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template slot="bottom-row">
          <td/><td/><td/><td/><td/><td/><td/><td/><td/><td/><td/>
          <td class="table-footer">
            TOTAL
          </td>
          <td class="table-footer">
            {{ dados != null && dados.totalQuantidade || '0' }}
          </td>
          <td class="table-footer">
            {{ dados != null && dados.totalValorParaSeguro ? maskCurrency(dados.totalValorParaSeguro) : '0' }}
          </td>
          <td class="table-footer">
            {{ dados != null && dados.totalValorTotalTaxado ? maskCurrency(dados.totalValorTotalTaxado) : '0' }}
          </td>
        </template>
      </b-table>
      <div class="m-2">
        <b-row v-if="dados && dados.totalRows > 0">
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando do 
              {{ Math.min(((currentPage - 1) * perPage) + 1, dados.totalRows) }}º até o 
              {{ Math.min(((currentPage - 1) * perPage) + perPage, dados.totalRows) }}º de {{
                dados.totalRows
              }} resultados</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="dados.totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="changePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <hr class="mb-0 mt-1">
    </b-card>
    <b-modal
      ref="modal"
      title="Detalhes Envio"
      ok-only
      ok-title="OK"
      size="50"
      style="max-height: 5000px"
      footer-class="d-flex justify-content-end"
    >
      <detalhes-envio :data="modalData" />
    </b-modal>
  </div>
</template>

<script>
import DateRange from '@/components/form/DateRange.vue'
import { reactive, ref, toRefs } from '@vue/composition-api'
import utils from '@/helpers/utils'
import EnviosComposition from './envios.composition'
import CteTrackingSidebar from './components/cte-tracking-sidebar/index'
import detalhesEnvio from './components/detalhesEnvio.vue'

export default {
  name: 'PesquisaEnvios',
  components: { DateRange, CteTrackingSidebar, detalhesEnvio },
  beforeMount() {
    this.$store.dispatch('clientes/findAll')
    this.$store.dispatch('departamentos/findAll')
  },
  setup() {
    const modal = ref(null)
    const { empresaId } = JSON.parse(localStorage.getItem('userName'))
    const state = reactive({
      modalData: {},
    })
    const abirTrack = data => {
      const urlCliente = process.env.VUE_APP_ROOT_API_CLIENTE      
      return `${urlCliente}track/${empresaId}-${data}`
    }
    
    const openModal = data => {
      state.modalData = data.item
      modal.value.show()
    }

    return {
      ...toRefs(state),
      ...EnviosComposition(),
      ...utils,
      abirTrack,
      openModal,
      modal,
    }
  },
}
</script>

<style scoped>
  .form-group {
    min-width: 100%;
  }
  .table-footer {
    font-weight: bold;
    background: #E9E9E9;
    border-bottom: 3px solid #F55559;
    font-size: 1.2rem;
  }
  .widerTh {
    min-width: 30rem;
    margin: 0 30px;
  }
  .button-group {
    margin: 0 2px;
  }
  .hyper-link {
    color: #263bdb;
    text-decoration: underline;
    cursor: pointer;
  }
</style>