<template>
  <div>
    <template v-if="usuario">
      <b-card>
        <b-row>

          <b-col
            cols="21"
            xl="6"
            class="d-flex justify-content-between flex-column"
          >
            <div class="d-flex justify-content-start">
              <b-avatar
                ref="previewEl"
                :text="avatarText(usuario.nome)"
                :variant="`light-success`"
                size="90px"
                rounded
              />
              <div class="d-flex flex-column ml-1">
                <h4 class="mb-1">
                  {{ usuario.nome }}
                </h4>
                <h5 class="mb-3">
                  {{ usuario.email }}
                </h5>
                <div class="d-flex flex-wrap">
                  <b-button
                    variant="primary"
                    :to="{ name: 'usuarios-prime-editar', params: { ...usuario.usuarioId } }"
                  >
                    Editar
                  </b-button>
                  <b-button
                    variant="outline-danger"
                    class="ml-1"
                    @click="deleteUser(usuario.usuarioId)"
                  >
                    Deletar
                  </b-button>
                </div>
              </div>
            </div>
          </b-col>
          <b-col
            cols="12"
            xl="6"
          >
            <table class="mt-2 mt-xl-0 w-100">
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="UserIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Nome</span>
                </th>
                <td class="pb-50">
                  {{ usuario.nome }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="UserIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Login(Email)</span>
                </th>
                <td class="pb-50">
                  {{ usuario.email }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="CheckIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Ativo</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ usuario.ativo ? 'Sim' : 'Não' }}
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
      </b-card>
    </template>
  </div>
</template>

<script>
import { avatarText } from '@core/utils/filter'
// import store from '@/store'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { reactive, toRefs } from '@vue/composition-api'
import Utils from '@/helpers/utils'

const usuariosStore = createNamespacedHelpers('usuarios')

export default {
  name: 'UsuariosVisualizar',
  beforeMount() {
    this.$store.dispatch('usuarios/findById', this.$route.params.usuarioId)
  },
  setup() {
    const state = reactive({
      usuario: usuariosStore.useGetters(['selected']).selected,
    })
    const currentUser = JSON.parse(localStorage.getItem('userName'))

    return {
      ...toRefs(state),
      ...Utils,
      avatarText,
      currentUser,
    }
  },
}
</script>

<style scoped>
</style>