<template lang="">
  <div>
    <b-card 
      no-body 
      class="mb-2"
    >
      <div class="m-2">
        <validation-observer #default="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(onSubmit)">
            <b-row>
              <b-col
                md="3"
              >
                <validation-provider 
                  #default="validationContext"
                  v-model="dateRange"
                  name="date-range"
                >
                  <date-range :date-range.sync="dateRange" />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="3"
              >
                <b-form-group
                  label="Cliente"
                  label-for="cliente"
                >
                  <v-select
                    id="cliente"
                    v-model="filtros.clienteId"
                    :options="selectClientes"
                    :reduce="val=>val.value"
                    :clearable="true"
                    placeholder="Todos"
                  />
                </b-form-group>
              </b-col>
              <b-col 
                md="6"
                sm="4"
                cols="12"
                class="d-flex justify-content-end align-items-center flex-wrap"
              >
                <b-button
                  type="submit"
                  variant="primary"
                  class="mb-sm-0 mr-0"
                >
                  Pesquisar
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-card>
    <b-card
      v-show="showResultados"
      no-body
      class="mb-2"
    >
      <b-card-header>
        <b-card-title class="font-medium-2 card-title">
          <feather-icon
            icon="TagIcon"
            size="18"
          />
          <span class="align-middle ml-50">
            Resultados
          </span>
        </b-card-title>
      </b-card-header>
      <hr class="my-0">
      <div class="m-2">
        <b-row>
          <b-col
            md="6"
            class="col-md-6 p-1"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                placeholder="Pesquisar..."
                debounce="1000"
              />
            </div>
          </b-col>
          <b-col
            md="6"
            class="col-md-6 d-flex align-items-center justify-content-start mb-1 mb-md-0 p-1"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>movimentações por página</label>
          </b-col>
        </b-row>
      </div>
      <template>
        <b-table
          ref="refAfericaoTable"
          responsive
          class="position-relative pb-3"
          :items="logsFtpList"
          :fields="column"
          :filter="search"
          :per-page="perPage"
          :current-page="currentPage"
          show-empty
          stacked="md"
          empty-text="Não há historico de importação via ftp nessa data."
        >
          <template #cell(modificado)="data">
            <span>{{ maskDateTime(data.item.modificado) }}</span>
          </template>
          <template #cell(url)="data">
            <a
              v-if="data.item.url"
              :href="`${data.item.url}`"
              target="_self"
              style="color: #3366CC"
              download
            >
              <feather-icon
                icon="DownloadIcon"
                size="18"
              />
            </a>
          </template>
        </b-table>
        <div class="m-2">
          <b-row v-if="totalRows > 0">
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Mostrando do 
                {{ Math.min(((currentPage - 1) * perPage) + 1, totalRows) }}º até o 
                {{ Math.min(((currentPage - 1) * perPage) + perPage, totalRows) }}º de {{
                  totalRows
                }} resultados</span>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
  </div>
</template>
<script>

// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { onBeforeMount, reactive, toRefs, watch } from '@vue/composition-api'
import DateRange from '@/components/form/DateRange.vue'
import moment from 'moment'
import Utils from '@/helpers/utils'
import { $themeConfig } from '@themeConfig'
import store from '@/store'

const { appCarregando } = $themeConfig.app
const clientesStore = createNamespacedHelpers('clientes')
const logsStore = createNamespacedHelpers('logs')

export default {
  name: 'ImportacaoFTPLog',
  components: { DateRange },
  setup() {
    const state = reactive({
      logsFtpList: logsStore.useGetters(['getFtpLogs']).getFtpLogs,
      clientes: clientesStore.useGetters(['clientes']).clientes,
      dateRange: {
        startDate: moment()
          .subtract(7, 'days')
          .format('YYYY-MM-DD'),
        endDate: Utils.dateRangeComTimeZone(),
      },
      filtros: {
        clienteId: null,
        dataInicial: new Date().toISOString().split('T')[0],
        dataFinal: new Date().toISOString().split('T')[0],
      },
      selectClientes: [],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      perPageOptions: [10, 25, 50, 100],
      search: '',
      showResultados: false,
    })

    const onSubmit = () => {
      state.currentPage = 1
      const filtroBuscaLogFtp = {
        dataInicio: state.dateRange.startDate,
        dataFim: state.dateRange.endDate,
        clienteId: state.filtros.clienteId,
      }
      appCarregando.show()
      store.dispatch('logs/getFtpLogs', filtroBuscaLogFtp).then(() => {
        appCarregando.hide()
        state.showResultados = true
      }).catch(() => {
        appCarregando.hide()
        swal.fire({
          icon: 'error',
          title: 'Erro ao carregar Logs!',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }) 
    }

    onBeforeMount(() => {
      store.dispatch('clientes/findAll')
    })

    watch(() => state.clientes, value => {
      value.forEach(elemento => {
        if (elemento.nomeFantasia !== 'Sem Cadastro') {
          state.selectClientes.push({ label: elemento.nomeFantasia,
            value: elemento.clienteId })
        }
      })
    })

    watch(() => state.logsFtpList, value => {
      if (value && value.length) {
        state.totalRows = value.length
      } else {
        state.totalRows = 0
      }
    })

    const column = [
      { key: 'nome', label: 'Nome Arquivo', sortable: true },
      { key: 'modificado', label: 'DataHora / Modificação', sortable: true },
      { key: 'url', label: 'Download', sortable: true },
    ]
    return {
      ...Utils,
      ...toRefs(state),
      column,
      onSubmit,
    }
  },
}
</script>
