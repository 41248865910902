<template>
  <b-sidebar
    id="add-new-user-prime-sidebar"
    :visible="active"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:active', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Adicionar Novo Usuário Prime
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <validation-observer
        #default="{handleSubmit}"
        ref="refFormObserver"
      >
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <h5 class="pb-1">
            Dados Gerais
          </h5>

          <validation-provider
            #default="validationContext"
            name="Nome Completo"
            rules="required"
          >
            <b-form-group
              label="Nome Completo *"
              label-for="nome"
            >
              <b-form-input
                id="nome"
                v-model="usuario.nome"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="João da Silva"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <h5 class="py-1">
            Dados de Login
          </h5>

          <validation-provider
            #default="validationContext"
            name="Login"
            rules="required|email"
          >
            <b-form-group
              label="Login(Email) *"
              label-for="login"
            >
              <b-form-input
                id="login"
                v-model="usuario.email"
                :state="getValidationState(validationContext)"
                trim
                placeholder="usuario@email.com"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Senha"
            rules="required"
          >
            <b-form-group
              label="Senha *"
              label-for="password"
            >
              <b-form-input
                id="password"
                v-model="usuario.password"
                :state="getValidationState(validationContext)"
                trim
                type="password"
                placeholder="******"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Confirme a senha"
            rules="required|confirmed:Senha"
          >
            <b-form-group
              label="Confirme a senha *"
              label-for="confirmPassword"
            >
              <b-form-input
                id="confirmPassword"
                v-model="usuario.confirmPassword"
                :state="getValidationState(validationContext)"
                trim
                type="password"
                placeholder="******"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Clientes"
            rules="required"
          >
            <b-form-group
              label="Clientes"
              label-for="clientesPrime"
            >
              <v-select
                id="clientesPrime"
                v-model="usuarioPrime.clienteId"
                multiple
                :options="clientesOptions"
                :reduce="val=>val.value"
                :close-on-select="false"
              />
              <span class="undermessage-error">
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Adicionar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import { reactive, toRefs, onBeforeMount } from '@vue/composition-api'
import cleaveOptions from '@/libs/cleave/options'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
// eslint-disable-next-line no-unused-vars
import store from '@/store'
import firebaseAlt from '@/libs/firebase/secundary-instance'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import { verifyErrorCode } from '@/libs/firebase/functions'

const clienteStores = createNamespacedHelpers('clientes')

export default {
  name: 'AddUsuarioPrimeSidebar',
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
  setup(_, { emit }) {
    const { empresaId } = JSON.parse(localStorage.getItem('userName'))
    onBeforeMount(() => {
      store.dispatch('clientes/findAll').then(() => {
        store.dispatch('clientes/setOptions')
      })
      store.dispatch('perfis/findAllComPermissao', empresaId).then(() => {
        store.dispatch('perfis/setOptions')
      })
    })

    const blankUsuario = {
      nome: null,
      email: null,
      password: null,
      confirmPassword: null,
      clientes: null,
      usuarioPrime: {
        clienteId: null,
        empresaId: null,
      },
    }

    const state = reactive({
      usuario: JSON.parse(JSON.stringify(blankUsuario)),
      clientesOptions: clienteStores.useGetters(['clientesOptions']).clientesOptions,
      usuarioPrime: {
        clienteId: null,
        empresaId: null,
      },
    })

    const resetUsuario = () => {
      state.usuario = JSON.parse(JSON.stringify(blankUsuario))
    }

    const appLoading = $themeConfig.app.appLoadingImage

    const error = codigoErro => {
      Vue.$toast(
        {
          component: ToastificationContent,
          props: {
            title: verifyErrorCode(codigoErro),
            icon: 'AlertCircleIcon',
            variant: 'primary',
          },
        },
        {
          timeout: 5000,
          draggable: true,
          hideProgressBar: false,
        },
      )
    }

    const onSubmit = () => {
      appLoading.show()
      const listPrime = []
      state.usuarioPrime.clienteId.forEach(c => {
        listPrime.push({
          clienteId: c,
          empresaId,
        })
      })
      firebaseAlt.auth().createUserWithEmailAndPassword(state.usuario.email, state.usuario.password)
        .then(userCredential => {
          const usuarioPrimeSave = {
            nome: state.usuario.nome,
            empresaId,
            email: state.usuario.email,
            firebaseUid: userCredential.user.uid,
            clienteId: listPrime[0].clienteId,
            ativo: 1,
            nivel: 'PRIME',
            usuarioPrime: listPrime,
          }
          firebaseAlt.auth().signOut()
          store.dispatch('usuarios/save', usuarioPrimeSave).then(() => {
            const { user } = userCredential
            user.sendEmailVerification()
            emit('aoSalvar')
            emit('update:active', false)
          }).then(() => {
            Vue.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Não esqueça de verificar sua caixa de spam!',
                  icon: 'MailIcon',
                  variant: 'warning',
                },
              },
              {
                timeout: 5000,
                draggable: true,
                hideProgressBar: false,
              },
            )
            Vue.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Email Enviado!',
                  icon: 'MailIcon',
                  variant: 'success',
                },
              },
              {
                timeout: 5000,
                draggable: true,
                hideProgressBar: false,
              },
            )
            Vue.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Usuário prime inserido com sucesso!',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              },
              {
                timeout: 5000,
                draggable: true,
                hideProgressBar: false,
              },
            )
          })
        })
        .catch(erro => {
          error(erro.code)
        })
        .finally(() => {
          appLoading.hide()
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetUsuario)

    return {
      ...toRefs(state),
      // Form Functions
      refFormObserver,
      getValidationState,
      resetForm,
      // cleave
      cleaveOptions,
      // methods
      onSubmit,
    }
  },
}
</script>

<style scoped>

</style>