<template>
  <b-card-text v-if="data">
    <b-row>

      <b-col>
        <b-card-text>
          <h4 class="my-2">
            Detalhes Envio
          </h4>
          <hr class="my-0">
          <div class="mx-1 pt-2">
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">Data Envio:</span>
              <span>{{ maskDate(data.dataEnvio) }}</span>
            </b-row>
            <hr class="my-0">
            <b-row class="pt-2 justify-content-between pb-1">
              <span class="font-weight-bold">Data Entrega Performance:</span>
              <span>{{ maskDate(data.dataPrazoEstimado) }}</span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">Prazo dias Importação Performance:</span>
              <span>{{ data.prazoEntregaDias }} dias</span>
            </b-row>
            <hr class="my-0">
            <b-row class="pt-2 justify-content-between pb-1">
              <span class="font-weight-bold">Data Entrega CIDATEN:</span>
              <span>{{ maskDate(data.dataEntregaCidaten) }}</span>
            </b-row>
            <b-row class="justify-content-between pb-1">
              <span class="font-weight-bold">Prazo dias tabela CIDATEN:</span>
              <span>{{ data.prazoEntregaDiasCidaten }} dias</span>
            </b-row>
          </div>
        </b-card-text>
      </b-col>
    </b-row>
  </b-card-text>
</template>

<script>
import utils from '@/helpers/utils'

export default {
  name: 'ModalVue',
  props: {
    data: {
      type: undefined,
      required: true,
    },
  },
  data() {
    return {
      ...utils,
      mostrarDepartamento: false,
    }
  },
  methods: {
    createDate(dateString) {
      const date = new Date(dateString)
      const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' }
      const output = date.toLocaleString('pt-BR', options)
      return output
    },
  },
}
</script>

<style scoped>

</style>